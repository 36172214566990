import React, { useEffect } from "react";
import { ITEMS_SPACE } from "./InitItemsSpace";
import { changeSpaceType } from "../components/ObjectLoaderSpace";

export const SelectSpaceComponent = (props) => {
  const divMenuPanel01 = "mb-4";
  const userAddress = props.addr;
  
  useEffect(() =>{
    setSelectedSpaceBorder();
  });

  return (
    <div className={ divMenuPanel01 }>
      <p className="w-full mb-1">SPACE TYPE</p>
      <div className="px-2 py-4 border-2 border-black rounded-lg mt-2 ">
        <p className="text-xs">変更するとこれまでのレイアウトはリセットされます。</p>
        <p className="text-xs">If you change it, the previous layout will be reset.</p>
        <div className="p-2 overflow-x-scroll grid grid-flow-col auto-cols-max gap-4">
          <div id="museum_01" className="w-full justify-between rounded-lg bg-gradient-to-r from-neutral-200 to-white px-2 py-4 mx-auto" key="1">
            <div className="mx-2">
              <div className="font-medium md:font-semibold break-all text-sm px-2">
                <div className="mb-4">BIG ROOM 01</div>
              </div>
              <div className="z-40 transform hover:scale-105 flex justify-center items-center">
                <button onClick={ () => selectSpaceType("museum_01", userAddress) }>
                  <img className="w-48 rounded-lg shadow-xl" src= "/images/Space001.png" />
                </button>
              </div>
            </div>
          </div>

          <div id="museum_02" className="w-full justify-between rounded-lg bg-gradient-to-r from-neutral-200 to-white px-2 py-4 mx-auto " key="2">
            <div className="mx-2">
              <div className="font-medium md:font-semibold break-all text-sm px-2">
                <div className="mb-4">SMALL ROOM 01</div>
              </div>
              <div className="z-40 transform hover:scale-105 flex justify-center items-center">
                <button onClick={ () => selectSpaceType("museum_02", userAddress) }>
                  <img className="w-48 rounded-lg shadow-xl" src= "/images/Space002.png" />
                </button>
              </div>
            </div>
          </div>

          <div id="museum_03" className="w-full justify-between rounded-lg bg-gradient-to-r from-neutral-200 to-white px-2 py-4 mx-auto " key="3">
            <div className="mx-2">
              <div className="font-medium md:font-semibold break-all text-sm px-2">
                <div className="mb-4">SMALL ROOM 02</div>
              </div>
              <div className="z-40 transform hover:scale-105 flex justify-center items-center">
                <button onClick={ () => selectSpaceType("museum_03", userAddress) }>
                  <img className="w-48 rounded-lg shadow-xl" src= "/images/Space003.png" />
                </button>
              </div>
            </div>
          </div>

          <div id="museum_100" className="w-full justify-between rounded-lg bg-gradient-to-r from-neutral-200 to-white px-2 py-4 mx-auto " key="4">
            <div className="mx-2">
              <div className="font-medium md:font-semibold break-all text-sm px-2">
                <div className="mb-4">CAFE 01</div>
              </div>
              <div className="z-40 transform hover:scale-105 flex justify-center items-center">
                <button onClick={ () => selectSpaceType("museum_100", userAddress) }>
                  <img className="w-48 rounded-lg shadow-xl" src= "/images/Space100.png" />
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )

}

function selectSpaceType(spaceType: string, userAddress: string) {
  switch(spaceType) {
    case "museum_01": {
      document.getElementById("museum_01").style.borderColor = "red";
      document.getElementById("museum_02").style.borderColor = "";
      document.getElementById("museum_03").style.borderColor = "";
      document.getElementById("museum_100").style.borderColor = "";
      document.getElementById("museum_01").style.borderWidth = "3px";
      document.getElementById("museum_02").style.borderWidth = "";
      document.getElementById("museum_03").style.borderWidth = "";
      document.getElementById("museum_100").style.borderWidth = "";
      ITEMS_SPACE.selectedSpaceType = spaceType;
      changeSpaceType(spaceType, userAddress);
      break;
    }
    case "museum_02": {
      document.getElementById("museum_01").style.borderColor = "";
      document.getElementById("museum_02").style.borderColor = "red";
      document.getElementById("museum_03").style.borderColor = "";
      document.getElementById("museum_100").style.borderColor = "";
      document.getElementById("museum_01").style.borderWidth = "";
      document.getElementById("museum_02").style.borderWidth = "3px";
      document.getElementById("museum_03").style.borderWidth = "";
      document.getElementById("museum_100").style.borderWidth = "";
      ITEMS_SPACE.selectedSpaceType = spaceType;
      changeSpaceType(spaceType, userAddress);
      break;
    }
    case "museum_03": {
      document.getElementById("museum_01").style.borderColor = "";
      document.getElementById("museum_02").style.borderColor = "";
      document.getElementById("museum_03").style.borderColor = "red";
      document.getElementById("museum_100").style.borderColor = "";
      document.getElementById("museum_01").style.borderWidth = "";
      document.getElementById("museum_02").style.borderWidth = "";
      document.getElementById("museum_03").style.borderWidth = "3px";
      document.getElementById("museum_100").style.borderWidth = "";
      ITEMS_SPACE.selectedSpaceType = spaceType;
      changeSpaceType(spaceType, userAddress);
      break;
    }
    case "museum_100": {
      document.getElementById("museum_01").style.borderColor = "";
      document.getElementById("museum_02").style.borderColor = "";
      document.getElementById("museum_03").style.borderColor = "";
      document.getElementById("museum_100").style.borderColor = "red";
      document.getElementById("museum_01").style.borderWidth = "";
      document.getElementById("museum_02").style.borderWidth = "";
      document.getElementById("museum_03").style.borderWidth = "";
      document.getElementById("museum_100").style.borderWidth = "3px";
      ITEMS_SPACE.selectedSpaceType = spaceType;
      changeSpaceType(spaceType, userAddress);
      break;
    }
  }
}

function setSelectedSpaceBorder() {
  switch(ITEMS_SPACE.selectedSpaceType) {
    case "museum_01": {
      document.getElementById("museum_01").style.borderColor = "red";
      document.getElementById("museum_01").style.borderWidth = "3px";
      break;
    }
    case "museum_02": {
      document.getElementById("museum_02").style.borderColor = "red";
      document.getElementById("museum_02").style.borderWidth = "3px";
      break;
    }
    case "museum_03": {
      document.getElementById("museum_03").style.borderColor = "red";
      document.getElementById("museum_03").style.borderWidth = "3px";
      break;
    }
    case "museum_100": {
      document.getElementById("museum_100").style.borderColor = "red";
      document.getElementById("museum_100").style.borderWidth = "3px";
      break;
    }
  }
}
